import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import isValidEmail from '@/utils/validations/isValidEmail';
import isNumber from '@/utils/validations/isNumber';

const useForgotPassword = () => {
  const router = useRouter();
  const store = useStore();

  const isLoading = ref(false);
  const userForm = ref({
    email: '',
    ruc: '',
  });
  const messageForm = ref({
    message: '',
    messageType: '',
  });
  const currentOption = ref('ruc');

  const resetMessages = () => {
    messageForm.value.message = '';
    messageForm.value.messageType = '';
  };
  const changeOption = (form) => {
    resetMessages();
    currentOption.value = form;
  };

  const showMessage = (message, messageType) => {
    isLoading.value = false;
    messageForm.value.message = message;
    messageForm.value.messageType = messageType;
  };

  const handleSubmit = async () => {
    isLoading.value = true;
    const option = currentOption.value;

    resetMessages();
    const ruc = userForm.value.ruc + '';

    if (!isValidEmail(userForm.value.email) && option === 'email') {
      showMessage('Ingrese un correo válido', 'error');
    } else if (!isNumber(userForm.value.ruc) && option === 'ruc') {
      showMessage('Ingrese un RUC válido', 'error');
    } else if ((ruc.length < 11 || ruc.length > 11) && option === 'ruc') {
      showMessage('Ingrese un RUC válido de 11 dígitos', 'error');
    } else {
      let value = '';
      let type = '';
      if (option === 'email') {
        value = userForm.value.email;
        type = '1';
      } else if (option === 'ruc') {
        type = '2';
        value = ruc;
      }

      const { success, message } = await store.dispatch('auth/forgotPassword', {
        value,
        type,
      });
      isLoading.value = false;
      if (success) {
        router.push({ name: 'email-confirmation-success' });
        return;
      }
      const messageType = success ? 'success' : 'error';

      messageForm.value.message = message;
      messageForm.value.messageType = messageType;
    }
  };

  const createPassword = async () => {
    isLoading.value = true;
    const value = localStorage.getItem('email') ?? '';
    let { success, message } = await store.dispatch('auth/forgotPassword', {
      value,
      register: 1,
      type: '1',
    });
    isLoading.value = false;
    const messageType = success ? 'success' : 'error';
    if (success) message = 'Correo enviado';

    messageForm.value.message = message;
    messageForm.value.messageType = messageType;
    setTimeout(function () {
      resetMessages();
    }, 1800);
  };

  return {
    isLoading,
    handleSubmit,
    changeOption,
    currentOption,
    messageForm,
    userForm,
    createPassword,
  };
};

export default useForgotPassword;
